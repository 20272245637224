<template lang="pug">
    .key-indicators-wrap
        ui-title.title(variant="subtitle", component="p") Целевые показатели стратегии
        .content-item(
            v-for="{ text, icon } in contentData",
            :key="text"
        )
            img(
                :src="require(`../../../../../assets/images/articles/6/key_indicators/${icon}.svg`)",
                alt="",
                loading="lazy",
                aria-hidden="true"
            )
            ui-paragraph.text
                strong(v-html="text")
        ui-paragraph.text-bottom(v-html="paragraph")
</template>

<script>
import UiTitle from '../../../../ui/Title.vue';
import UiParagraph from '../../../../ui/Paragraph.vue';

export default {
    name: 'key-indicators',
    components: {
        UiParagraph,
        UiTitle,
    },
    computed: {
        /* eslint-disable max-len */
        contentData() {
            return [
                {
                    icon: '1',
                    text: 'Снижение смертности от&nbsp;туберкулеза на&nbsp;95% к&nbsp;2035 году по&nbsp;сравнению с&nbsp;2015 годом',
                },
                {
                    icon: '2',
                    text: 'Снижение заболеваемости туберкулезом на&nbsp;90% к&nbsp;2035 году по&nbsp;сравнению с&nbsp;2015 годом',
                },
                {
                    icon: '3',
                    text: 'К&nbsp;2035 году ни&nbsp;одна из&nbsp;семей, где есть больные туберкулезом, не&nbsp;должна нести катастрофические расходы',
                },
            ];
        },
        paragraph() {
            return 'Приоритет борьбы с&nbsp;туберкулезом в&nbsp;России и&nbsp;в&nbsp;мире был определен на&nbsp;<a href="https://www.who.int/news-room/events/first-who-global-ministerial-conference-on-ending-tb-in-the-sustainable-development-era-a-multisectoral-response" target="_blank" rel="noopener noreferrer">Первой глобальной министерской конференции ВОЗ &laquo;Ликвидировать туберкулёз в&nbsp;эпоху устойчивого развития: многосекторальный подход&raquo;</a>. Итоги конференции на&nbsp;уровне министров были приняты к&nbsp;сведению Совещанием высокого уровня по&nbsp;туберкулезу Генеральной Ассамблеи ООН в&nbsp;2018&nbsp;году.';
        },
        /* eslint-enable max-len */
    },
};
</script>

<style lang="scss" scoped>
.key-indicators-wrap {
    width: 100%;
    max-width: 750px;
    margin: rem(96) auto;

    .title {
        margin-bottom: rem(32);
    }

    .content-item {
        width: 100%;
        margin: rem(24) 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: rem(84);
            height: rem(74);
            object-fit: contain;
        }

        .text {
            width: calc(100% - #{rem(84)});
            padding-left: rem(42);
        }
    }

    .text-bottom {
        margin-top: rem(48);
    }

    @media (--viewport-tablet) {
        max-width: unset;
        margin: rem-mobile(64) auto;
        padding: 0 var(--offset-x);

        title {
            margin-bottom: rem-mobile(32);
        }

        .content-item {
            margin: rem-mobile(24) 0;
            display: block;

            img {
                width: rem-mobile(84);
                height: rem-mobile(74);
                margin: rem-mobile(24) auto;
                display: block;
                object-fit: contain;
            }

            .text {
                width: 100%;
                padding-left: 0;
            }
        }

        .text-bottom {
            margin-top: rem-mobile(48);
        }
    }
}
</style>
